module.exports = {
  ROLES: {
    ambassador: 'ambassador', //Used in src/api/chord-services.js
    customer: 'customer',
    affiliate: 'affiliate'
  },
  REQ_STATUS: {
    IDLE: 'idle',
    PENDING: 'pending',
    RESOLVED: 'resolved',
    REJECTED: 'rejected'
  },
  CART_STATE: {
    COMPLETE: 'complete'
  },
  MOBILE_WIDTH: 768
}
