import React from 'react'
import useAllProductTypesCollectionsQuery from '~/hooks/graphql/queries/all-product-types-collections'
import { Link } from 'gatsby'
import { useApp } from '~/context/app-context'

export const NavTopDropdown = () => {
  const { affiliate } = useApp()
  const { productTypes, collections } = useAllProductTypesCollectionsQuery()

  return (
    <div className="container mx-0">
      <div className="row">
        <div className="col-4">
          <p className="fw-bold fs-24">PRODUCT TYPES</p>
          {productTypes.map(item => {
            return (
              <Link
                className="text-secondary text-uppercase fs-20 fw-bold d-block mb-2"
                to={`/shop/type/${item.slug}`}
                title={item.name}
                key={item.id}
              >
                {item.name}
              </Link>
            )
          })}
        </div>
        <div className="col-4">
          <p className="fw-bold fs-24">FOR HELP WITH:</p>
          {collections.map(collection => {
            return (
              <Link
                className="text-blue-light text-uppercase fs-20 fw-bold d-block mb-2"
                to={`/shop/purpose/${collection.slug}`}
                title={collection.title}
                key={collection.id}
              >
                {collection.title}
              </Link>
            )
          })}
        </div>
        <div className="col-4">
          <input type="text" className="form-control w-100" placeholder="Search" />
          {affiliate !== null && (
            <div className="mt-5 fs-20">
              Shopping with: <strong>{affiliate.name}</strong>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
export default NavTopDropdown
