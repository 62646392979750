import React from 'react'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import { Link } from 'gatsby'

export const BoxMenu = ({ items, title }) => {
  if (items.length === 0) {
    return <></>
  }
  return (
    <>
      <p className="mb-3 text-gray-400 fw-bold">{title}</p>
      {items.map((item, idx) => {
        if (item.anchor) {
          return (
            <AnchorLink
              key={idx + item.slug + item.title}
              to={item.slug}
              title={item.title}
              className="mt-3 text-white fw-bold"
            />
          )
        }
        return (
          <Link to={item.slug} key={idx + item.slug + item.title} className="mt-3 text-white fw-bold">
            {item.title}
          </Link>
        )
      })}
    </>
  )
}
BoxMenu.defaultProps = {
  items: [],
  title: ''
}
export default BoxMenu
