import CookieConsent from "react-cookie-consent"
import React from 'react'

const gdprform = () => (
	<CookieConsent
		enableDeclineButton
        location="bottom"
        buttonText="Accept"
        declineButtonText="Decline"
        cookieName="gatsby-gdpr-google-analytics">
		This site uses cookies to enhance the user experience.
	</CookieConsent>
)

export default gdprform