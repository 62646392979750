import React, { useEffect, useState } from 'react'
import { AFFILIATE_CODE_LENGTH, useContentfulCustomer } from '~/hooks/contenful-customer'
import { navigate } from 'gatsby'

const AppContext = React.createContext()

const windowGlobal = typeof window !== 'undefined' && window

function useApp() {
  const context = React.useContext(AppContext)
  if (context === undefined) {
    throw new Error(`useApp must be used within a AppProvider`)
  }
  return context
}

const AppProvider = ({ children }) => {
  const [affiliate, setAffiliate] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [affCode, setAffCode] = useState(null)

  const parsedUrl = windowGlobal && new URL(window.location.href)

  const {
    contentfulCustomer: { findByCode: findCustomer }
  } = useContentfulCustomer()

  useEffect(() => {
    const utmCampaignParam = parsedUrl.searchParams.get('utm_campaign')
    if (utmCampaignParam !== null && utmCampaignParam !== '') {
      setAffCode(utmCampaignParam)
    }

    const pathAffCode = getAffiliateCode()
    if (pathAffCode) {
      setAffCode(pathAffCode.affCode)
      navigate(pathAffCode.cleanHref)
    }

    getAffiliate()
  }, [])

  useEffect(() => {
    if (affCode !== null) {
      const checkAffiliateUser = async () => {
        try {
          let contentfulUser = await findCustomer(affCode)
          if (contentfulUser !== null) {
            window.sessionStorage.setItem('commonsAffiliate', JSON.stringify(contentfulUser))
          }
        } catch (error) {}
      }
      checkAffiliateUser()
    }
    return () => {}
  }, [affCode])

  const getAffiliateCode = () => {
    const parsedUrl = new URL(window.location.href)
    const regExpr = new RegExp('(/with/){1}(.{' + AFFILIATE_CODE_LENGTH + '})$', 'gm')
    const result = parsedUrl.pathname.match(regExpr)
    if (result?.length === 1) {
      const code = result[0].replace('/with/', '')
      return {
        affCode: code,
        cleanHref: parsedUrl.href.replace(`/with/${code}`, '')
      }
    }
    return null
  }

  const getAffiliate = () => {
    const storedAffiliate = window.sessionStorage.getItem('commonsAffiliate')
    if (storedAffiliate) {
      setAffiliate(JSON.parse(storedAffiliate))
    }
  }

  return (
    <AppContext.Provider
      value={{
        affiliate,
        isLoading,
        getAffiliate
      }}
    >
      {children}
    </AppContext.Provider>
  )
}
export { AppProvider, useApp }
