/** @jsx jsx */
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Box, Button, Flex, Heading, jsx, Text } from 'theme-ui'
import FormInputWithLabel from '~/components/Generic/Form/InputWithLabel'
import { analytics, logSentryError, useTranslate } from '@chordcommerce/gatsby-theme-autonomy'
import UserService from '~/api/services'
import { FaAngleRight } from 'react-icons/fa'

const FooterNewsletterSignup = () => {
  const translate = useTranslate()
  const { trackEmailCaptured } = analytics
  const { register, handleSubmit, errors } = useForm()
  const [submitted, setSubmitted] = useState(false)

  const onSubmit = async data => {
    const { email } = data

    const payload = {
      email: email,
      placement: {
        page: 'all-pages',
        component: 'footer',
        website: window.location.hostname
      }
    }

    try {
      trackEmailCaptured(payload)
      await UserService.subscribeToNotificationList(data.email, process.env.GATSBY_KLAVYIO_NEWSLETTER_ID)
      setSubmitted(true)
    } catch (error) {
      logSentryError(error, { source: 'FooterNewsletterSignup' })
    }
  }

  return (
    <Box
      sx={{
        paddingBottom: ['2rem', '2.75rem', '0'],
        borderBottom: ['1px solid', null, 'none'],
        textAlign: ['center', null, 'left'],
        width: ['100%']
      }}
    >
      <Box>
        <Heading
          className="mb-2 txt-gray-400 fw-bold"
          sx={{
            variant: ['text.h3', null, 'text.h2']
          }}
        >
          {translate('footer.newsletter_signup.title')}
        </Heading>
        <Text
          className="txt-gray-400 mx-auto "
          sx={{
            marginBottom: ['16px', null, '24px'],
            maxWidth: [null, '60%', 'none']
          }}
        >
          {translate('footer.newsletter_signup.text')}
        </Text>

        {!submitted && (
          <Flex
            as="form"
            onSubmit={handleSubmit(onSubmit)}
            sx={{
              flexDirection: ['column', null, 'row'],
              flexWrap: 'wrap',
              margin: [null, 'auto'],
              width: [null, '295px', 'auto']
            }}
          >
            <Box
              className="rounded mt-3 position-relative bg-white flex-grow-1"
              sx={{
                marginRight: [null, null, '12px']
              }}
            >
              <FormInputWithLabel
                name="email"
                title={translate('footer.newsletter_signup.input_label')}
                label={translate('footer.newsletter_signup.input_label')}
                placeholder={translate('footer.newsletter_signup.input_label')}
                aria-label={translate('footer.newsletter_signup.input_label')}
                {...register('email', {
                  required: true,
                  pattern: {
                    value: /^\S+@\S+$/i,
                    message: translate('validation.invalid_email')
                  }
                })}
              />
            </Box>
            {errors && errors['email'] && (
              <Text
                variant="formError"
                sx={{
                  backgroundColor: 'errorLight',
                  padding: '12px 12px',
                  order: [null, null, 3],
                  marginBottom: ['0.75rem', null, '0']
                }}
              >
                {errors['email'].type === 'required' && translate('validation.required_field')}

                {errors['email'].type === 'pattern' && translate('validation.invalid_email')}
              </Text>
            )}
            <Button className="bg-transparent position-absolute submit_arrow" type="submit" sx={{ flexShrink: 0 }}>
              <FaAngleRight />
            </Button>
          </Flex>
        )}

        {submitted && (
          <Box
            sx={{
              margin: [null, 'auto'],
              maxWidth: [null, '295px', 'none'],
              textAlign: ['center', null, 'left']
            }}
          >
            {translate('footer.newsletter_signup.confirmation')}
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default FooterNewsletterSignup
