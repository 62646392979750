import React from 'react'
import { Link } from 'gatsby'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import BoxMenu from '~/components/Footer/BoxMenu'
import FooterNewsletterSignup from '~/components/Footer/NewsletterSignup'
import Logo from '~/assets/images/logos/logo_light_xl.svg'

export const Footer = () => {
  const policyLinks = [
    { title: 'Cookies Policy', slug: '/cookies-policy', anchor: false },
    { title: 'Privacy Policy', slug: '/privacy-policy', anchor: false },
    { title: 'Terms', slug: '/terms', anchor: false }
  ]
  const commonsLinks = [
    { title: 'Shop', slug: '/shop', anchor: false },
    { title: 'Learn', slug: '/learn', anchor: false },
    { title: 'About', slug: '/about-us', anchor: false }
  ]
  const communityLinks = [
    { title: 'Join', slug: '/become-ambassador', anchor: false },
    { title: 'Common Club', slug: '/account/login', anchor: false }
  ]
  return (
    <>
      <footer className="container-xxl px-sm-6 text-lg-white">
        <div className="d-none d-lg-block">
          <div className="container px-5 pb-5 bg-primary">
            <div className="row py-5">
              <div className="col-12">
                <Logo alt="commons" />
              </div>
            </div>
            <div className="row">
              <div className="col-md-3 d-flex flex-column">
                <BoxMenu items={commonsLinks} title="Commons" />
              </div>
              <div className="col-md-3 d-flex flex-column">
                <BoxMenu items={communityLinks} title="Community" />
              </div>
              <div className="col-md-2 d-flex flex-column">
                <p className="mb-3 text-gray-400 fw-bold">Support</p>
                <AnchorLink to="/learn#FAQ" title="FAQs" className="mt-3 text-white fw-bold" />
                <Link to="/returns-and-exchanges" className="mt-3 text-white fw-bold">
                  Returns & Exchanges
                </Link>
                <div className="mt-3 text-white fw-bold">help@commons.co</div>
              </div>
              <div className="col-md-4 d-flex flex-column">
                <FooterNewsletterSignup />
              </div>
            </div>
          </div>
          <div className="container py-3 px-6 bg-blue-dark">
            <div className="foo-privacy row fs-14 text-gray-400">
              <div className="col-md-6">2021 Commons</div>
              <div className="col-md-6 text-decoration-underline d-flex justify-content-end">
                {policyLinks.map((item, idx) => {
                  return (
                    <Link to={item.slug} key={idx + item.slug + item.title} className="text-gray-400 me-4">
                      {item.title}
                    </Link>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
        <span className="d-lg-none align-self-end pb-2 ">
          Prices shown reflect product price after discounts, but not including shipping and tax.
        </span>
      </footer>
    </>
  )
}

export default Footer
