import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

export const NavTopLink = ({ item, classNames }) => {
  return (
    <Link
      to={`/${item.slug}`}
      className={`py-4 align-self-center me-0 me-lg-5 text-blue-light fw-bold fs-18 ${classNames}`}
    >
      {item.name}
    </Link>
  )
}

NavTopLink.propTypes = {
  classNames: PropTypes.string,
  item: PropTypes.shape({
    name: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired
  })
}
NavTopLink.defaultProps = {
  classNames: ''
}
export default NavTopLink
