import { logSentryError } from '@chordcommerce/gatsby-theme-autonomy'
import { useEffect, useState } from 'react'
import { REQ_STATUS, ROLES } from '~/config'

const contentful = require('contentful')

const contenfulManagement = require('contentful-management')

const clientDelivery = contentful.createClient({
  accessToken: process.env.GATSBY_CONTENTFUL_ACCESS_TOKEN,
  space: process.env.GATSBY_CONTENTFUL_SPACE_ID
})

export const AFFILIATE_CODE_LENGTH = 8
export const useContentfulCustomer = () => {
  const [ambassadors, setAmbassadors] = useState([])
  const [fetchStatus, setFetchStatus] = useState(REQ_STATUS.IDLE)
  const [fetchError, setFetchError] = useState(null)

  useEffect(() => {
    return () => {
      setAmbassadors(null)
      setFetchStatus(REQ_STATUS.IDLE)
      setFetchError(null)
    }
  }, [])

  const generateAffiliateCode = userId => {
    userId = userId.toString()
    let result = ''
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    for (let i = 0; i < AFFILIATE_CODE_LENGTH - userId.length; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length))
    }
    return userId + result
  }

  const find = email => {
    if (email === undefined || email === null || email === '') {
      return null
    }
    return clientDelivery
      .getEntries({
        content_type: 'ecommerceCustomers',
        'fields.email': email
      })
      .then(response => {
        let customer = null
        if (response.items.length > 0) {
          const result = response.items[0] //The contentful customer email is unique
          customer = {
            name: result.fields.name,
            email: result.fields.email,
            role: result.fields.role,
            affiliateCode: result.fields.affiliateCode
          }
        }
        return customer
      })
      .catch(err => {
        logSentryError(err, { source: 'Contenful get ecommerceCustomers' })
        return null
      })
  }

  const findByCode = affiliateCode => {
    if (affiliateCode === undefined || affiliateCode === null || affiliateCode === '') {
      return null
    }
    return clientDelivery
      .getEntries({
        content_type: 'ecommerceCustomers',
        'fields.affiliateCode': affiliateCode
      })
      .then(response => {
        let customer = null
        if (response.items.length > 0) {
          const result = response.items[0] //The contentful customer affiliateCode is unique
          customer = {
            name: result.fields.name,
            email: result.fields.email,
            role: result.fields.role,
            chordUserId: result.fields.chordUserId,
            affiliateCode: result.fields.affiliateCode
          }
        }
        return customer
      })
      .catch(err => {
        logSentryError(err, { source: 'Contenful get ecommerceCustomers' })
        return null
      })
  }

  const create = (userId, userEmail, userName, ambassadorData) => {
    setFetchStatus(REQ_STATUS.PENDING)
    contenfulManagement
      .createClient({
        accessToken: process.env.CONTENTFUL_MANAGEMENT_TOKEN
      })
      .getSpace(process.env.GATSBY_CONTENTFUL_SPACE_ID)
      .then(space => space.getEnvironment(process.env.CONTENTFUL_ENVIRONMENT))
      .then(environment =>
        environment.createEntry('ecommerceCustomers', {
          fields: {
            name: { 'en-US': userName },
            email: { 'en-US': userEmail },
            chordUserId: { 'en-US': userId.toString() },
            affiliateCode: { 'en-US': generateAffiliateCode(userId) },
            role: { 'en-US': ROLES.ambassador },
            ambassdorAgreementText: { 'en-US': JSON.parse(ambassadorData) }
          }
        })
      )
      .then(entry => entry.publish())
      .then(() => {
        setFetchStatus(REQ_STATUS.RESOLVED)
      })
      .catch(err => {
        logSentryError(err, { source: 'Contenful create ecommerceCustomers' })
        setFetchStatus(REQ_STATUS.REJECTED)
        setFetchError(err.message)
      })
  }

  //TODO this should be async
  //https://www.contentful.com/developers/docs/references/content-delivery-api/#/reference/search-parameters/select-operator/query-entries/console/js
  const loadAmbassadors = () => {
    return clientDelivery
      .getEntries({
        content_type: 'ecommerceCustomers',
        select: 'sys.id,fields.email,fields.name,fields.affiliateCode,fields.chordUserId',
        'fields.role': ROLES.ambassador
        // 'fields.email[match]': 'omer@'
      })
      .then(response => {
        let data = []
        if (response.items.length > 0) {
          response.items.map(item => {
            data.push({
              id: item.sys.id,
              name: item.fields.name,
              email: item.fields.email,
              chordUserId: item.fields.chordUserId,
              affiliateCode: item.fields.affiliateCode
            })
          })
        }
        return data
      })
      .catch(err => {
        logSentryError(err, { source: 'Contenful get ambassadors' })
        return []
      })
  }

  const contentfulCustomer = { loadAmbassadors, ambassadors, create, fetchStatus, fetchError, find, findByCode }

  return { contentfulCustomer }
}
