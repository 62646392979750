import React, { useEffect, useState } from 'react'
import { useUser as useChordUser, useAuth as useChordAuth } from '@chordcommerce/gatsby-theme-autonomy'

import { useContentfulCustomer } from '~/hooks/contenful-customer'
import { navigate } from 'gatsby'

const AuthContext = React.createContext()

const AuthProvider = ({ children }) => {
  const { user: chordUser, loadUser: loadChordUser } = useChordUser()
  const { getToken, logout } = useChordAuth()
  const [user, setUser] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [isLoggedIn, setIsLoggedIn] = useState(false)

  const {
    contentfulCustomer: { find: findCustomer }
  } = useContentfulCustomer()

  useEffect(() => {
    const checkStatus = async () => {
      try {
        setIsLoading(true)
        let userIsLoggedIn = false
        let fetchIsLoading = true
        const storedUser = window.sessionStorage.getItem('user')
        if (storedUser) {
          console.log('User in sessionStorage')
          userIsLoggedIn = true
          fetchIsLoading = false
          setUser(JSON.parse(storedUser))
        } else {
          console.log('Request Initiated!')
          await getToken()
          await loadChordUser()

          // sometimes, loadChordUser() retrieves only the ID from memo
          //Ensure that the choardUser is fully loaded and has email.
          if (chordUser.data.email) {
            let contentfulUser = await findCustomer(chordUser.data.email)

            //Since only ambassadors are stored in Contentful, set role customer by default
            let fullUserData = { ...chordUser.data, ...{ role: 'customer' } }
            if (contentfulUser !== null) {
              fullUserData = { ...chordUser.data, ...contentfulUser }
            }

            window.sessionStorage.setItem('user', JSON.stringify(fullUserData))
            setUser(fullUserData)
            userIsLoggedIn = true
            fetchIsLoading = false
          }
        }
        setIsLoading(fetchIsLoading)
        setIsLoggedIn(userIsLoggedIn)
      } catch (error) {
        console.log('User not authenticated', error)
        window.sessionStorage.removeItem('user')
        setIsLoading(false)
        setIsLoggedIn(false)
      }
    }
    checkStatus()
  }, [chordUser.data.email, loadChordUser, getToken])

  const signOut = async () => {
    setIsLoading(true)
    await logout()
    window.sessionStorage.removeItem('user')
    setIsLoading(false)
    setIsLoggedIn(false)
    navigate('/')
    console.log('User signOut')
  }

  if (isLoading) return 'Loading..'
  return (
    <AuthContext.Provider
      value={{
        user,
        isLoggedIn,
        isLoading,
        signOut
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

function useAuth() {
  const context = React.useContext(AuthContext)
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`)
  }
  return context
}
export { AuthProvider, useAuth }
