import { graphql, useStaticQuery } from 'gatsby'
const useAllProductTypesCollectionsQuery = () => {
  const data = {
    productTypes: [],
    collections: []
  }
  const result = useStaticQuery(graphql`
    query AllCollectionsProductType {
      allContentfulCollection(
        filter: { showOnWeb: { eq: true }, catalog: { elemMatch: { identifier: { eq: "products-and-kit-catalog" } } } }
      ) {
        nodes {
          title
          slug
          id
        }
      }
      allContentfulProductType {
        nodes {
          name
          slug
          id
          description
          fullDescription {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  `)

  data.productTypes = result.allContentfulProductType.nodes.map(item => {
    return {
      id: item.id,
      name: item.name,
      slug: item.slug,
      description: item.description,
      fullDescription: item.fullDescription?.childMarkdownRemark.html
    }
  })
  data.collections = result.allContentfulCollection.nodes.map(item => {
    return {
      id: item.id,
      title: item.title,
      slug: item.slug
    }
  })
  return data
}
export default useAllProductTypesCollectionsQuery
