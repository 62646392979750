import React, { useEffect, useState } from 'react'
import useNavigationQuery from '~/hooks/graphql/queries/use-navigation'
import { Navbar, Nav, Container, NavDropdown } from 'react-bootstrap'
import Logo from '~/assets/images/logos/logo_blue_xl.svg'
import Logo_sm from '~/assets/images/logos/logo_blue_sm.svg'
import IconBag from '~/assets/images/shop-bag.svg'
import NavTopLink from '~/components/NavTop/NavTopLink'
import { NavTopDropdown } from '~/components/NavTop/NavTopDropdown'
import { useCart } from '@chordcommerce/gatsby-theme-autonomy'
import { Link, navigate } from 'gatsby'

export const NavTop = () => {
  const { cart } = useCart()
  const { pages } = useNavigationQuery()
  const [cartTotalQty, setCartTotalQty] = useState(0)
  const [showShopMenu, setShowShopMenu] = useState(false)

  useEffect(() => {
    if (!cart.isFetching) {
      setCartTotalQty(cart.data.totalQuantity)
    }
  }, [cart])

  useEffect(() => {
    const topNavbar = document.getElementById('top-navbar-nav')
    const dropdownMenuElement = document.getElementsByClassName('dropdown-menu')
    if (dropdownMenuElement.length > 0 && null !== topNavbar) {
      dropdownMenuElement[0].style.width = topNavbar.offsetWidth + 'px'
    }
  }, [])
  const onToggle = () => {
    setShowShopMenu(false)
    navigate('/shop')
  }

  const onHoverShop = show => {
    setShowShopMenu(show)
  }

  return (
    <Navbar expand="lg" className="bg-white py-0">
      <Container className="d-flex px-0">
        <Navbar.Brand className="d-lg-none d-flex col-sm-8 col-md-4 justify-content-center" href="/">
          <Logo_sm />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="top-navbar-nav" />
        <Navbar.Collapse id="top-navbar-nav">
          <Nav className="d-flex w-100 align-items-center">
            <Nav.Item className="d-flex col-12 col-lg-4 flex-column flex-lg-row">
              <NavDropdown
                onMouseEnter={() => onHoverShop(true)}
                onMouseLeave={() => onHoverShop(false)}
                show={showShopMenu}
                renderMenuOnMount={true}
                onToggle={onToggle}
                title="SHOP"
                className="py-2 align-self-center me-0 me-lg-5 border-0"
                id="basic-nav-dropdown"
              >
                <NavTopDropdown />
              </NavDropdown>
              {pages.map(page => {
                return <NavTopLink key={page.id} item={page} />
              })}
            </Nav.Item>
            <Nav.Item className="d-none d-lg-flex col-4 justify-content-center">
              <Nav.Link href="/" title="commons">
                <Logo />
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className="d-flex flex-column flex-lg-row col-12 col-lg-4 flex-row justify-content-end align-items-center">
              <NavTopLink item={{ name: 'JOIN / EARN', slug: 'account' }} />
              <Link to="/cart" title="My Cart" className="position-relative pe-3">
                {cartTotalQty > 0 && <span className="cart-count">{cartTotalQty}</span>}
                <IconBag alt="My Cart" title="My Cart" />
              </Link>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

export default NavTop
