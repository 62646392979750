import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import Footer from '~/components/Footer'
import GDPR from '~/components/GDPR'
import NavTop from '~/components/NavTop'
import NotificationTopBar from '~/components/NotificationTopBar'
import AmbassadorNav from '~/components/NavTop/AmbassadorNav'
import { AuthProvider, useAuth } from '~/context/auth-context'
import { AppProvider } from '~/context/app-context'

const Layout = ({ children }) => {
  const [headerHeight, setHeaderHeight] = useState(0)
  const [scrolled, setScrolled] = useState(true)

  const { user, isLoading } = useAuth()
  const isAmbassadorAccount = user?.role === 'ambassador'

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  })

  useEffect(() => {
    if (null !== document.getElementById('headerSection')) {
      setHeaderHeight(document.getElementById('headerSection').offsetHeight)
    }
  }, [])

  const handleScroll = () => {
    // TODO handle on mobile version
    setScrolled(true)
  }

  return (
    <>
      <div id="headerSection" className={`container-xxl bg-white ${scrolled ? 'is-sticky' : ''}`}>
        <div className="row">
          <div className="col-12 px-0 px-sm-6">
            <NotificationTopBar />
          </div>
        </div>
        <div className="row">
          <div className="col-12 px-sm-6">
            {!isLoading && isAmbassadorAccount ? <AmbassadorNav account={user} /> : <NavTop />}
          </div>
        </div>
      </div>
      <main className="container-xxl px-sm-6" style={{ marginTop: headerHeight + 'px' }}>
        {children}
      </main>
      <GDPR />
      <Footer />
    </>
  )
}

const LayoutWithContext = ({ children }) => (
  <AuthProvider>
    <AppProvider>
      <Layout children={children} />
    </AppProvider>
  </AuthProvider>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default LayoutWithContext
