import { graphql, useStaticQuery } from 'gatsby'

const useNotificationBarQuery = () => {
  const result = {
    notification: null,
    referralNotification: null
  }

  const data = useStaticQuery(graphql`
    {
      notificationBar: contentfulNotificationBar(slug: { eq: "notification-bar" }) {
        notification {
          slug
          description {
            description
            childMarkdownRemark {
              html
            }
          }
        }
      }
      referralNotification: contentfulNotification(slug: { eq: "referral" }) {
        slug
        description {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  `)

  if (data && data.notificationBar && data.notificationBar.notification.description.description) {
    result.notification = data.notificationBar.notification.description.description
  }
  if (data && data.referralNotification && data.referralNotification.description) {
    result.referralNotification = data.referralNotification.description
  }

  return result
}

export default useNotificationBarQuery
