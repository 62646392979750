import React from 'react'
import useNotificationBarQuery from '~/hooks/graphql/queries/use-notification-bar'

const NotificationTopBar = () => {
  const { notification } = useNotificationBarQuery()
  if (notification === null) {
    return <></>
  }
  return <div className="w-100 font-sfpro-bold text-center bg-primary text-white py-3">{notification}</div>
}

export default NotificationTopBar
