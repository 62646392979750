import React, { useEffect, useState } from 'react'
import { Navbar, Nav, Container } from 'react-bootstrap'
import Logo from '~/assets/images/logos/logo_blue_xl.svg'
import NavTopLink from '~/components/NavTop/NavTopLink'
import PropTypes from 'prop-types'
import IconBag from '~/assets/images/shop-bag.svg'
import { Link } from 'gatsby'
import { useCart } from '@chordcommerce/gatsby-theme-autonomy'

export const AmbassadorNav = ({ account }) => {
  const { cart } = useCart()
  const [cartTotalQty, setCartTotalQty] = useState(0)

  useEffect(() => {
    if (!cart.isFetching) {
      setCartTotalQty(cart.data.totalQuantity)
    }
  }, [cart])

  return (
    <Navbar expand="lg" className="bg-white py-0">
      <Container>
        <Navbar.Toggle aria-controls="top-navbar-nav" />
        <Navbar.Collapse id="top-navbar-nav">
          <Nav className="d-flex w-100 align-items-center">
            <Nav.Item className="d-flex col-4 flex-row">
              <NavTopLink item={{ name: 'DASHBOARD', slug: 'ambassador' }} />
              <NavTopLink item={{ name: 'ORDERS', slug: 'ambassador/orders' }} />
              <NavTopLink item={{ name: 'REPORTS', slug: 'ambassador/reports' }} />
            </Nav.Item>
            <Nav.Item className="d-flex col-4 justify-content-center">
              <Nav.Link href="/" title="commons">
                <Logo />
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className="d-flex flex-column flex-lg-row col-12 col-lg-4 flex-row justify-content-end align-items-center">
              <NavTopLink item={{ name: account.email, slug: 'account' }} />
              <Link to="/cart" title="My Cart" className="position-relative pe-3">
                {cartTotalQty > 0 && <span className="cart-count">{cartTotalQty}</span>}
                <IconBag alt="My Cart" title="My Cart" />
              </Link>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

AmbassadorNav.propTypes = {
  account: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string.isRequired
  })
}

export default AmbassadorNav
